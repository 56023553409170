<template>
  <a href="./">
    <img class="logo" alt="Vue logo" src="./assets/logo.png" />
  </a>
  <h1>{{ title }}</h1>
  <div class="version">ver. {{ version }}</div>
  <div>User: {{ userMsg }}</div>
  <div v-if="signedIn && user">Email: {{ user.email }}</div>
  <div v-if="signedIn && user"><img :src="user.photoURL" /></div>
  <button v-if="signedIn && user" type="button" @click="doLogout()">
    Sign Out
  </button>
  
  <!-- eslint-disable-next-line -->
  <SignIn v-if="!signedIn" :auth="auth" />
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import SignIn from "./components/SignIn.vue";

const checking = "checking ...";
const prefix = "/api/v1/";

export default {
  name: "App",
  components: {
    SignIn,
  },
  data() {
    return {
      userMsg: checking,
      signedIn: true,
      title: "This Application name",
      version: "0.0.0",
    };
  },
  mounted: function () {
    console.log("started");

    // Get title and version from local storage
    if (localStorage.title) this.title = localStorage.title;
    if (localStorage.version) this.version = localStorage.version;

    // Get title and version from server
    this.axios.get(prefix + "name").then((response) => {
      this.title = response.data;
      localStorage.title = response.data;
    });
    this.axios.get(prefix + "version").then((response) => {
      this.version = response.data;
      localStorage.version = response.data;
    });

    // Init Firebase Auth
    const firebaseConfig = {
      apiKey: "AIzaSyABOvyym5VChUeHam8h0xGvTvzRDcRm0xk",
      authDomain: "teo-test.firebaseapp.com",
    };
    const fbapp = initializeApp(firebaseConfig);
    this.auth = getAuth(fbapp);

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("user signed in", uid);

        user.getIdToken(true).then((idToken) => {
          console.log("token:", idToken);
          this.axios.get(prefix + "checkToken/" + idToken).then((response) => {
            console.log(response.data);
            if (response.data !== "ok") {
              // Set error message here
              console.log("can't use this IdToken in server");
              return;
            }
            if (!user.emailVerified) {
              console.log("email has not verified");
              this.signedIn = false;
              this.doLogout();
              return;
            }
            this.user = user;
            this.signedIn = true;
            this.userMsg = "signed in";
          });
        });
        // ...
      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.userMsg = "signed out";
        console.log("user is signed out");
      }
    });
  },
  methods: {
    doLogout() {
      signOut(this.auth)
        .then(() => {
          // Sign-out successful.
          console.log("signOut successful");
        })
        .catch((error) => {
          // An error happened.
          console.log("signOut error:", error);
        });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

img.logo {
  width: 100px;
}

.version {
  margin-top: -15px;
  margin-bottom: 15px;
}
</style>
